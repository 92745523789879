import { Main } from 'layouts'
import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { ScrollSequenceStatic } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { ProductBreakdownConnected } from 'components/ProductBreakdown'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'
import { IndustryLeaders } from 'components/Phantom/_sections/IndustryLeaders'
import { HeroConnected } from './components'
import { SplitFeatureRiskFree } from 'components/SplitFeature'
import { BetterSleepDrawers } from 'pageCores/prospectingCMin/localComponents'
import { Awards } from 'pageCores/homepage/components/Awards'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { AutopilotBrieferCTAStoreConnected } from 'components/Phantom/_sections/AutopilotBriefer'
import { WhoWeAre } from 'components/Phantom/_sections/WhoWeAre'
import { TruemedIntraPageComponentConnected } from 'components/TruemedIntraPageComponent'

const Homepage = () => {
	return (
		<Main inverted>
			<HeroConnected />
			<NewStandardConnected
				ctaUrl="/pod-cover"
				afterIntelligentSystem
			/>
			<ProductBreakdownConnected theme={'light'} />
			<SplitFeatureRiskFree />
			<FeaturedReviews />
			<IndustryLeaders theme={'light'} />
			<PersonalSleepConnected
				showExtraSleep={true}
				header={"Sleep that's tailored to you"}
				subHeader={"The Pod personalizes your side of the bed, creating the ultimate environment for deep, restorative sleep—even if you and your partner can't agree on the perfect temperature."}
				cta={{
					href: '/product/pod-cover',
					text: 'Shop the sale',
				}}
			/>
			<AutopilotBrieferCTAStoreConnected />
			{/* TODO move to global component */}
			<WhoWeAre />
			<BetterSleepDrawers
				theme={'dark'}
				cta={{ href: '/product/pod-cover', text: 'Shop the sale' }}
			/>
			<TruemedIntraPageComponentConnected />
			<ScrollSequenceStatic ctaUrl="/product/pod-cover" />
			<Awards theme={'light'} />
		</Main>
	)
}

export default Homepage
