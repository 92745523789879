import { FC, useRef } from 'react'
import styles from './Awards.module.scss'
import { AwardsProps } from './Awards.types'
import { AwardProps, getDefaultAwards } from 'components/_const_assets/awards'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const Awards: FC<AwardsProps> = (props) => {
	const { theme = 'dark', awards = getDefaultAwards(props.theme || 'light') } = props
	return (
		<section
			className={styles.container}
			id={'short-awards'}
			data-theme={theme}
		>
			<div className={styles.wrapper}>
				<ul className={styles.awards_list}>
					{awards.map((award, index) => {
						return (
							<>
								{index !== 0 && <div className={styles.award_separator} />}
								<li key={award.title}>
									<Award
										{...award}
										index={index}
									/>
								</li>
							</>
						)
					})}
				</ul>
			</div>
		</section>
	)
}

const Award: FC<AwardProps & { index: number }> = (props) => {
	const ref = useRef<HTMLDivElement>(null)

	useAnimateInOnScroll(ref, { delay: 0.1 * props.index })

	return (
		<div
			className={styles.awards_wrapper}
			ref={ref}
		>
			<div className={styles.award_logo}>{props.logo}</div>
			<div className={styles.award_text}>
				<Type.Eyebrow className={styles.award_year}>{props.year}</Type.Eyebrow>
				<Type.Headline5
					as={'p'}
					className={styles.award_title}
				>
					{props.title}
				</Type.Headline5>
			</div>
		</div>
	)
}
