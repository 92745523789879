import { PortraitProps } from './types'

export const getDefaultPortraits = (): PortraitProps[] => {
	return [
		{
			name: 'Peter Attia, MD',
			title: 'Physician and founder of Early Medical',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/attia-color.png',
		},
		{
			name: 'Andrew Huberman, Ph.D.',
			title: 'Neuroscientist and Stanford professor',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/huberman-color.png',
		},
		{
			name: 'Matthew Walker, Ph.D.',
			title: 'Neuroscientist & UC Berkeley professor',
			image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/walker-color.png',
		},
	]
}
