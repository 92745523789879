import { FC, useEffect, useRef } from 'react'
import styles from './Hero.module.scss'
import { HeroProps } from './Hero.types'
import { Badge } from 'components/Badge'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'
import { createNoise2D } from 'simplex-noise'
import { Clock } from 'three'

import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'

/**
 * Generates a random state for the simplex noise generator. The offsets here aren't super important, it's
 * just to make each element start at a different position in the noise field. 2000 is an arbitrary number.
 * @param element The element to generate the state for
 */
const generateRandomState = (element: HTMLElement) => {
	return {
		xOffset: Math.round(Math.random() * 2000),
		yOffset: Math.round(Math.random() * 2000),
		element,
	}
}

const OFFSETS = {
	gift1: 350,
	gift2: 150,
	gift3: 300,
	pedestalHub: 100,
}

export const HeroConnected = observer((props: Partial<HeroProps>) => {
	const { priceStore } = useRootStore()

	const eyebrow = 'Early Access'
	const headline = 'Get an early start this Black Friday'
	const subheadline = `Shop Black Friday early to get up to ${priceStore.allProductsDiscountNumberFormatted} off ultimate sleep`
	const cta = {
		text: 'Shop the sale',
		href: '/product/pod-cover',
	}
	return (
		<Hero
			eyebrow={eyebrow}
			headline={headline}
			subheadline={subheadline}
			cta={cta}
			{...props}
		/>
	)
})

export const Hero: FC<HeroProps> = (props) => {
	const { eyebrow, headline, subheadline, cta } = props

	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const gift1 = document.getElementById('bfcm_gift_1')
		const gift2 = document.getElementById('bfcm_gift_2')
		const gift3 = document.getElementById('bfcm_gift_3')
		const bfcm_hub = document.getElementById('bfcm_hub')
		if (!gift1 || !gift2 || !gift3 || !bfcm_hub) return () => {}

		const noise = createNoise2D()
		const SCALE = 20

		const states = [gift1, gift2, gift3, bfcm_hub].map((it) => generateRandomState(it))

		let animationId: number

		const clock = new Clock()

		const animate = () => {
			animationId = requestAnimationFrame(animate)

			const time = clock.getElapsedTime()

			for (const state of states) {
				const xNoise = noise(state.xOffset, time * 0.1)
				const yNoise = noise(state.yOffset, time * 0.1)

				const rotationalNoise = noise(state.xOffset + state.yOffset, time * 0.1)

				state.element.style.setProperty('--gift-x', `${xNoise * SCALE}px`)
				state.element.style.setProperty('--gift-y', `${yNoise * SCALE}px`)
				state.element.style.setProperty('--gift-rotation', `${rotationalNoise * 2}deg`)
			}
		}
		animationId = requestAnimationFrame(animate)

		return () => {
			cancelAnimationFrame(animationId)
		}
	})

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)
		const INTRO_SETTINGS = {
			duration: 1,
			ease: 'power1.out',
		}

		const timeline = gsap.timeline({ defaults: INTRO_SETTINGS })
		timeline.to(
			'#bfcm_gift_1_wrapper',
			{
				y: -1 * OFFSETS.gift1,
			},
			0
		)
		timeline.to(
			'#bfcm_gift_2_wrapper',
			{
				y: -1 * OFFSETS.gift2,
			},
			0
		)

		timeline.to(
			'#bfcm_gift_3_wrapper',
			{
				y: -1 * OFFSETS.gift3,
			},
			0
		)

		timeline.to(
			'#bfcm_pedestal_wrapper',
			{
				y: -1 * OFFSETS.pedestalHub,
			},
			0
		)

		const reverseTimeline = gsap.timeline()
		reverseTimeline.to(
			'#bfcm_gift_1_outer',
			{
				y: OFFSETS.gift1,
			},
			0
		)

		reverseTimeline.to(
			'#bfcm_gift_2_outer',
			{
				y: OFFSETS.gift2,
			},
			0
		)

		reverseTimeline.to(
			'#bfcm_gift_3_outer',
			{
				y: OFFSETS.gift3,
			},
			0
		)

		reverseTimeline.to(
			'#bfcm_pedestal',
			{
				y: OFFSETS.pedestalHub,
			},
			0
		)

		reverseTimeline.to(
			'#bfcm_hub_outer',
			{
				y: OFFSETS.pedestalHub,
			},
			0
		)

		const scrollTrigger = new ScrollTrigger({
			trigger: containerRef.current,
			start: 'top top',
			end: 'bottom top',
			scrub: 1,
			animation: reverseTimeline,
		})
	})

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<header>
				<Badge.BFCMDark>{eyebrow}</Badge.BFCMDark>
				<Type.Headline1>{headline}</Type.Headline1>
				<Type.Headchapter>{subheadline}</Type.Headchapter>
				<Button.White
					id={'hero-cta'}
					href={cta.href}
				>
					{cta.text}
				</Button.White>
			</header>
			<div className={styles.media_container}>
				<div className={styles.elements}>
					<div
						id={'bfcm_pedestal_wrapper'}
						className={styles.pedestal_wrapper}
						style={{
							top: OFFSETS.pedestalHub,
						}}
					>
						<div id={'bfcm_hub_outer'}>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcmpedestal/bfcmpedestal-hub-trimmed.png'}
								alt={''}
								className={styles.hub_layer}
								id={'bfcm_hub'}
							/>
						</div>
						<Img
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcmpedestal/bfcmpedestal-trimmed.png'}
							alt={''}
							className={styles.pedestal_layer}
							id={'bfcm_pedestal'}
							objectFit={'cover'}
						/>
					</div>

					<div
						className={styles.gift_1_wrapper}
						id={'bfcm_gift_1_wrapper'}
						style={{
							top: OFFSETS.gift1,
						}}
					>
						<div id={'bfcm_gift_1_outer'}>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcmpedestal/bfcmpedestal-gift1-trimmed.png'}
								alt={''}
								className={styles.gift_1}
								id={'bfcm_gift_1'}
							/>
						</div>
					</div>

					<div
						className={styles.gift_2_wrapper}
						id={'bfcm_gift_2_wrapper'}
						style={{
							top: OFFSETS.gift2,
						}}
					>
						<div id={'bfcm_gift_2_outer'}>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcmpedestal/bfcmpedestal-gift2-trimmed.png'}
								alt={''}
								className={styles.gift_2}
								objectFit={'cover'}
								id={'bfcm_gift_2'}
							/>
						</div>
					</div>

					<div
						className={styles.gift_3_wrapper}
						id={'bfcm_gift_3_wrapper'}
						style={{
							top: OFFSETS.gift3,
						}}
					>
						<div id={'bfcm_gift_3_outer'}>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcmpedestal/bfcmpedestal-gift3-trimmed.png'}
								alt={''}
								className={styles.gift_3}
								objectFit={'cover'}
								id={'bfcm_gift_3'}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
