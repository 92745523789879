import { FastCompany, MensHealth, Time, WomensHealth } from './companyLogos'
import { ReactNode } from 'react'

export interface AwardProps {
	year: string
	title: string
	logo: ReactNode
}

export const getDefaultAwards = (theme: 'light' | 'dark' = 'dark') => {
	const awards = []
	if (theme === 'light') {
		awards.push(FastCompanyLightProps)
	} else {
		awards.push(FastCompanyProps)
	}

	awards.push(MensHealthProps, WomensHealthProps, TimeProps)
	return awards
}

const FastCompanyLightProps: AwardProps = {
	year: '2019, 2022 & 2023',
	title: 'Most Innovative Companies',
	logo: FastCompany('black'),
}

const FastCompanyProps: AwardProps = {
	year: '2019, 2022 & 2023',
	title: 'Most Innovative Companies',
	logo: FastCompany('white'),
}

const MensHealthProps: AwardProps = {
	year: '2021',
	title: 'Men’s Health Sleep Award',
	logo: MensHealth('#f70000'),
}

const WomensHealthProps: AwardProps = {
	year: '2021',
	title: 'Women’s Health Sleep Award',
	logo: WomensHealth('#f70000'),
}

const TimeProps: AwardProps = {
	year: '2018 & 2019',
	title: 'Best Inventions',
	logo: Time('#ff0000'),
}
